import React from 'react';
import { Button, Grid } from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
  hasRelationWarnings,
  EntityRelationWarning,
} from '@backstage/plugin-catalog';
import {
  EntityGithubActionsContent,
  isGithubActionsAvailable,
} from '@backstage-community/plugin-github-actions';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EmptyState } from '@backstage/core-components';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
// import {
//   EntityJenkinsContent,
//   EntityLatestJenkinsRunCard,
//   isJenkinsAvailable,
// } from '@backstage-community/plugin-jenkins';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { EntityKubernetesContent , isKubernetesAvailable } from '@backstage/plugin-kubernetes';
// import { GitlabPipelineList, GitlabPipelinesOverview, isGitlabAvailable } from '@veecode-platform/backstage-plugin-gitlab-pipelines';
// import {
//   isGitlabAvailable,
//   EntityGitlabLanguageCard,
//   EntityGitlabMergeRequestsTable,
//   EntityGitlabMergeRequestStatsCard,
//   EntityGitlabPeopleCard,
//   EntityGitlabPipelinesTable,
//   EntityGitlabReadmeCard,
//   EntityGitlabReleasesCard,
// } from '@immobiliarelabs/backstage-plugin-gitlab';
import {
  EntityArgoCDOverviewCard,
  isArgocdAvailable
} from '@roadiehq/backstage-plugin-argo-cd';
import {
  isTektonCIAvailable,
  TektonCI,
} from '@janus-idp/backstage-plugin-tekton';
// import {
//   isPulumiAvailable,
//   EntityPulumiCard,
//   EntityPulumiMetdataCard,
//   PulumiComponent
// } from '@pulumi/backstage-plugin-pulumi';
// import {
//   HarborPage,
//   isHarborAvailable,
// } from '@vrabbi/backstage-plugin-harbor'
// import {
//   isNexusRepositoryManagerExperimentalAvailable,
//   NexusRepositoryManagerPage,
// } from '@janus-idp/backstage-plugin-nexus-repository-manager';
import { EntitySonarQubeCard, isSonarQubeAvailable } from '@backstage-community/plugin-sonarqube';
// import { EntityCostInsightsContent } from '@internal/plugin-cost-insights-tsystems';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
// import {EntityAmazonEcsServicesContent} from '@aws/amazon-ecs-plugin-for-backstage';
import { EntityTeamPullRequestsContent } from '@backstage-community/plugin-github-pull-requests-board';
// import { EntityKialiContent } from '@janus-idp/backstage-plugin-kiali';
import {
  GithubIssuesCard,
  GithubIssuesPage,
} from '@backstage-community/plugin-github-issues';

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
    </TechDocsAddons>
  </EntityTechdocsContent>
);
// const pulumiContent = (
//   <EntitySwitch>
//       <EntitySwitch.Case if={isPulumiAvailable}>
//           <PulumiComponent/>
//       </EntitySwitch.Case>
//   </EntitySwitch>
// );
const argocdContent = ( 
  <EntitySwitch>
    <EntitySwitch.Case if={e => isArgocdAvailable(e)}> 
        <EntityArgoCDOverviewCard /> 
    </EntitySwitch.Case>
  </EntitySwitch>
)
// const harborContent = (
//   // TODO: Uncomment harbor when plugin installed
//   <EntitySwitch>
//     <EntitySwitch.Case if={e => isHarborAvailable(e)}> 
//         <HarborPage  /> 
//     </EntitySwitch.Case>
//   </EntitySwitch>
//   <EntitySwitch>
//   <EntitySwitch.Case if={e => isArgocdAvailable(e)}> 
//       <EntityArgoCDOverviewCard /> 
//   </EntitySwitch.Case>
//   </EntitySwitch>
// )
const cicdContent = (
<>
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>
  </EntitySwitch>

  <EntitySwitch>
    <EntitySwitch.Case if={isTektonCIAvailable}>
      <TektonCI />
    </EntitySwitch.Case>
  </EntitySwitch>
 
  {/* <EntitySwitch>

    <EntitySwitch.Case if={isJenkinsAvailable}>
      <EntityJenkinsContent />
    </EntitySwitch.Case>

  </EntitySwitch> */}

  {/* <EntitySwitch>

  //TODO: Solve this plugin
   <EntitySwitch.Case if={isGitlabAvailable}>
      <Grid item sm={12} md={3} lg={3}>
          <EntityGitlabReleasesCard />
      </Grid>
      <Grid item md={12}>
          <EntityGitlabPipelinesTable />
      </Grid>
      <Grid item md={12}>
          <EntityGitlabMergeRequestsTable />
      </Grid>
    </EntitySwitch.Case>
  </EntitySwitch> */}
</>  
);

// const nexusContent = (
//   <EntitySwitch>
//    <EntitySwitch.Case if={isNexusRepositoryManagerExperimentalAvailable}>
//       <NexusRepositoryManagerPage />
//     </EntitySwitch.Case>
//   </EntitySwitch>
// );

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>

    <Grid item md={4} xs={12}>
      <EntityLinksCard />
    </Grid>
    <Grid item md={8} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>
    
    {/* <EntitySwitch>
      <EntitySwitch.Case if={isGithubActionsAvailable}>
        <Grid item md={6}>
          <EntityRecentGithubActionsRunsCard limit={4} variant="gridItem" />
        </Grid>
      </EntitySwitch.Case>
      <EntitySwitch.Case if={isPulumiAvailable}>
        <Grid item md={6}>
            <EntityPulumiCard variant="gridItem" />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch> */}
    {/* <EntitySwitch>
        <EntitySwitch.Case if={e => Boolean(isArgocdAvailable(e))}>
          <Grid item sm={8} xs={12}>
            <EntityArgoCDOverviewCard />
          </Grid>
        </EntitySwitch.Case>
    </EntitySwitch>     */}
  </Grid>
);

const ServiceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/github-actions" title="GitHub Actions">
      <EntityGithubActionsContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/ci-cd" title="CI/CD" if={e =>  isTektonCIAvailable (e) || isGithubActionsAvailable (e) || isJenkinsAvailable (e) || isGitlabAvailable(e)}> */}
    <EntityLayout.Route path="/ci-cd" title="CI/CD" if={e =>  isGithubActionsAvailable (e) }>
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="github-issues" title="Issues">
      <GithubIssuesPage />
    </EntityLayout.Route>

    <EntityLayout.Route path="/argocd" title="ArgoCD" if={e =>  isArgocdAvailable (e) }>
      {argocdContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/pulumi" title="Pulumi" if={isPulumiAvailable}>
        {pulumiContent}
    </EntityLayout.Route> */}

    {/* //TODO: Add this ECS frontend plugin
    < EntityLayout.Route path = "/ecs" title = "Amazon ECS" >
      <EntityAmazonEcsServicesContent />
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/build-artifacts" title="Build Artifacts" if={e =>  isNexusRepositoryManagerExperimentalAvailable(e)}>
      {nexusContent}
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/quality" title="Quality" if={e => isSonarQubeAvailable(e) } >
      <Grid item>        
        <EntitySonarQubeCard variant="gridItem" />
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    {/* //TODO: Add kubernetes plugin */}
    <EntityLayout.Route path="/kubernetes" title="Kubernetes" if={e => isKubernetesAvailable(e) }>
      <EntityKubernetesContent refreshIntervalMs={30000} />
    </EntityLayout.Route>

    {/*<EntityLayout.Route path="/harbor" title="Harbor" if={e => isHarborAvailable(e) }>
      {harborContent}
  </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/kiali" title="kiali">
      <EntityKialiContent />
    </EntityLayout.Route> */}

    {/* //TODO: Costs plugin not migrated */}
    {/* {deploymentEnv === 'aws' && (
        <EntityLayout.Route path="/costs" title="Cloud Costs">
        <EntityCostInsightsContent/>
      </EntityLayout.Route>
    )} */}
    
  </EntityLayout>
);

const WebsiteEntityPage = () => {
  const deploymentEnv = useApi(configApiRef).getString('backend.deploymentEnv');

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        {overviewContent}
      </EntityLayout.Route> 
      {/* <EntityLayout.Route path="/ci-cd" title="CI/CD" if={e =>  isTektonCIAvailable (e) || isGithubActionsAvailable (e) || isJenkinsAvailable (e) || isGitlabAvailable(e)}> */}
      <EntityLayout.Route path="/ci-cd" title="CI/CD" if={e =>  isGithubActionsAvailable (e) }>
        {cicdContent}
      </EntityLayout.Route>
      {/* <EntityLayout.Route path="/argocd" title="ArgoCD" if={e =>  isArgocdAvailable (e) }>
        {argocdContent}
      </EntityLayout.Route>
      <EntityLayout.Route path="/pulumi" title="Pulumi" if={isPulumiAvailable}>
          {pulumiContent}
      </EntityLayout.Route> */}
      <EntityLayout.Route path="/dependencies" title="Dependencies">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6}>
            <EntityDependsOnComponentsCard variant="gridItem" />
          </Grid>
          <Grid item md={6}>
            <EntityDependsOnResourcesCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      {/* <EntityLayout.Route path="/build-artifacts" title="Build Artifacts" if={e =>  isNexusRepositoryManagerExperimentalAvailable(e)}>
        {nexusContent}
      </EntityLayout.Route> */}
      <EntityLayout.Route path="/docs" title="Docs">
        {techdocsContent}
      </EntityLayout.Route>
      <EntityLayout.Route path="/quality" title="Quality" if={e => isSonarQubeAvailable(e) } >
        <Grid item>        
          <EntitySonarQubeCard variant="gridItem" />
        </Grid>
      </EntityLayout.Route>
      {/* <EntityLayout.Route path="/kubernetes" title="Kubernetes" if = { isKubernetesAvailable }>
        <EntityKubernetesContent refreshIntervalMs={30000} />
      </EntityLayout.Route> */}

      {/* <EntityLayout.Route path="/harbor" title="Harbor" if={e => isHarborAvailable(e) }>
        {harborContent}
      </EntityLayout.Route> */}
      {/* //TODO: Costs plugin not migrated */}
      {/* {deploymentEnv === 'aws' && (
        <EntityLayout.Route path="/costs" title="Cloud Costs">
        <EntityCostInsightsContent/>
      </EntityLayout.Route>
      )} */}
    </EntityLayout>
  );
}

const WebsiteEntityPageRX = () => {
  return <WebsiteEntityPage />
}

const LibraryEntityPage = () => {
  const deploymentEnv = useApi(configApiRef).getString('backend.deploymentEnv');
  return (<EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route> 
    <EntityLayout.Route path="/ci-cd" title="CI/CD" if={e =>  isTektonCIAvailable (e) || isGithubActionsAvailable (e)}>
    {/* <EntityLayout.Route path="/ci-cd" title="CI/CD" if={e =>  isTektonCIAvailable (e) || isGithubActionsAvailable (e) || isJenkinsAvailable (e) || isGitlabAvailable(e)}> */}
      {cicdContent}
    </EntityLayout.Route>
    // <EntityLayout.Route path="/argocd" title="ArgoCD" if={e =>  isArgocdAvailable (e) }>
    //   {argocdContent}
    // </EntityLayout.Route>
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    {/* <EntityLayout.Route path="/build-artifacts" title="Build Artifacts" if={e =>  isNexusRepositoryManagerExperimentalAvailable(e)}>
      {nexusContent}
    </EntityLayout.Route> */}
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/quality" title="Quality" if={e => isSonarQubeAvailable(e) } >
      <Grid item>        
        <EntitySonarQubeCard variant="gridItem" />
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/kubernetes" title="Kubernetes" if = { isKubernetesAvailable }>
      <EntityKubernetesContent refreshIntervalMs={30000} />
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/harbor" title="Harbor" if={e => isHarborAvailable(e) }>
      {harborContent}
    </EntityLayout.Route> */}

    {/* //TODO: Costs plugin not migrated */}
    {/* {deploymentEnv === 'aws' && (
        <EntityLayout.Route path="/costs" title="Cloud Costs">
        <EntityCostInsightsContent/>
      </EntityLayout.Route>
    )} */}
  </EntityLayout>
);
}

const LibraryEntityPageRX = () => {
  return <LibraryEntityPage />
}

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (

  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {ServiceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      <WebsiteEntityPageRX />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('library')}>
      <LibraryEntityPageRX />
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityMembersListCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityLinksCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      <EntityTeamPullRequestsContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid item md={8}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
        {/* <EntitySwitch>
          <EntitySwitch.Case if={isJenkinsAvailable}>
            <Grid item sm={6}>
              <EntityLatestJenkinsRunCard
                branch="main,master"
                variant="gridItem"
              />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch> */}
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
