import { createBaseThemeOptions, createUnifiedTheme, genPageTheme, palettes, shapes } from "@backstage/theme";
// import './../fonts/fonts.css';

export const LightTheme = createUnifiedTheme({ 
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#e20074',
      },
      secondary: {
        main: '#565a6e',
      },
      error: {
        main: '#8c4351',
      },
      warning: {
        main: '#8f5e15',
      },
      info: {
        main: '#34548a',
      },
      success: {
        main: '#485e30',
      },
      background: {
        default: '#f4f4f4',
        paper: '#ffffff',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#121212',
        link: '#565a6e',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#121212',
      navigation: {
        background: '#121212',
        indicator: '#e20074',
        color: '#d5d6db',
        selectedColor: '#ffffff',
      },
    },
  }),
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#e20074', '#000000'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#e20074', '#000000'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#e20074', '#000000'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#e20074', '#000000'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#e20074', '#000000'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#e20074', '#000000'],
      shape: shapes.wave,
    }),
    resource: genPageTheme({ colors: ['#e20074', '#000000'], shape: shapes.wave }),
    other: genPageTheme({ colors: ['#e20074', '#000000'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#e20074', '#000000'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#e20074', '#000000'], shape: shapes.wave }),
  },
});
